import axios from "axios"
import {
  GetContentResponse,
  GetHistorialChartsResponse,
  GetOverviewResponse,
  PostDetailsItemResponse,
  PostDetailsResponse,
} from "./types/api-response"
import {
  getPostDetailsMockedData,
  getSummaryMockedData,
  getHistoricalMockedData,
} from "../../data"
import { monthOptions } from "../../app/shared/SelectDate/SelectDate"
import { SocialNetworks } from "../social/types/social-networks.enum"
import { SortDirection, SortModel } from "../social/types/sort-model"

async function getOverviewMetrics({
  month,
  year,
  account,
}: {
  month: string
  year: string
  account?: string
}) {
  return await axios.get<GetOverviewResponse>(
    `/metrics/${SocialNetworks.TIK_TOK}/overview?date=${year}-${month}-01`,
  )
}

function getContentMetrics({
  month,
  year,
  provider,
  account,
}: {
  month: string
  year: string
  provider: SocialNetworks
  account?: string
}): GetContentResponse {
  // return axios.get<GetContentResponse>(
  //   `/social/content?date=${year}-${month}-01&provider=${provider}`,
  // )

  const summaryMetric = getSummaryMockedData()

  const detailsMetrics = getPostDetailsMockedData().filter(
    (metric) => metric.provider === provider,
    // &&
    //   metric.date.includes(
    //     monthOptions.find((x) => x.value === Number(month))?.label || "",
    //   ) &&
    //   metric.date.includes(year),
  )

  return {
    summary: summaryMetric,
    details: {
      data: detailsMetrics.slice(0, 3),
      pagination: {
        page: 1,
        totalPages:
          detailsMetrics.length > 0 ? Math.ceil(detailsMetrics.length / 3) : 1,
      },
    },
  }
}

function getContentDetailsMetrics({
  month,
  year,
  page,
  provider,
  search,
  sortModel,
  account,
}: {
  month: string
  year: string
  page: number
  provider: SocialNetworks
  search: string
  sortModel?: SortModel
  account?: string
}): PostDetailsResponse {
  // return axios.get<PostDetailsItemResponse[]>(
  //   `/social/content-details?date=${year}-${month}-01&page=${page}&provider=${provider}`,
  // )

  const detailsMetrics = getPostDetailsMockedData().filter(
    (metric) =>
      metric.provider === provider &&
      metric.description.toLowerCase().startsWith(search.toLowerCase()),
    //  &&
    // metric.date.includes(
    //   monthOptions.find((x) => x.value === Number(month))?.label || "",
    // ) &&
    // metric.date.includes(year),
  )
  const pageSize = 3
  return {
    data: (sortModel
      ? sortData(detailsMetrics, sortModel.field, sortModel.direction)
      : detailsMetrics
    ).slice((page - 1) * pageSize, page * pageSize),
    pagination: {
      page,
      totalPages:
        detailsMetrics.length > 0
          ? Math.ceil(detailsMetrics.length / pageSize)
          : 1,
    },
  }
}

function getHistoricalMetrics({
  month,
  year,
  account,
}: {
  month: string
  year: string
  account?: string
}) {
  return axios.get<GetHistorialChartsResponse>(
    `/metrics/${SocialNetworks.TIK_TOK}/historical?date=${year}-${month}-01`,
  )
}

function sortData(
  array: PostDetailsItemResponse[],
  field: string,
  order: SortDirection,
): PostDetailsItemResponse[] {
  const convertDateStringToDate = (date: string) => {
    const dateRegex = /^(\w+) (\d+), (\d+) (\d+):(\d+)(am|pm)$/i // April 23, 2023 05:15pm

    const match = date.match(dateRegex) || []
    const month = monthOptions.find((x) => x.label === match[1])?.value
    const day = match[2]
    const year = match[3]
    let hour = parseInt(match[4])
    const minute = match[5]
    const period = match[6].toLowerCase()

    if (period === "pm" && hour < 12) {
      hour += 12
    } else if (period === "am" && hour === 12) {
      hour = 0
    }

    const formattedDate = `${year}-${
      Number(month) >= 10 ? month : "0" + month
    }-${Number(day) >= 10 ? day : "0" + day}T${hour
      .toString()
      .padStart(2, "0")}:${minute}:00`

    return new Date(formattedDate)
  }

  return array.sort((a, b) => {
    let valueA = (a as any)[field]
    let valueB = (b as any)[field]

    if (field === "date") {
      valueA = convertDateStringToDate(a.date)
      valueB = convertDateStringToDate(b.date)
    }
    if (field === "impressions") {
      valueA = a.metrics.reduce(
        (acc, curr) => (curr.metric === "Impressions" ? curr.value : acc),
        0,
      )

      valueB = b.metrics.reduce(
        (acc, curr) => (curr.metric === "Impressions" ? curr.value : acc),
        0,
      )
    }
    if (field === "eng_rate") {
      valueA = a.metrics.reduce(
        (acc, curr) => (curr.metric === "Eng. Rate" ? curr.value : acc),
        0,
      )

      valueB = b.metrics.reduce(
        (acc, curr) => (curr.metric === "Eng. Rate" ? curr.value : acc),
        0,
      )
    }

    if (order === SortDirection.ASC) {
      return valueA > valueB ? 1 : -1
    } else {
      return valueA < valueB ? 1 : -1
    }
  })
}
const tikTokAPI = {
  getOverviewMetrics,
  getContentMetrics,
  getContentDetailsMetrics,
  getHistoricalMetrics,
}
export default tikTokAPI
