import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  LineController,
  registerables,
  TooltipItem,
} from "chart.js"
import { useEffect, useRef } from "react"
import { Chart } from "react-chartjs-2"
import { useAppSelector } from "../../../../app/hooks"
import { selectCollapsedMenu } from "../../../../app/layouts/Dashboard/menu/menuSlice"

ChartJS.register(
  ...registerables,
  LineController,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
)

export const options = {
  responsive: true,
  maintainAspectRatio: true,
  aspectRatio: 4,
  interaction: {
    mode: "index" as const,
    intersect: false,
  },
  stacked: false,
  scales: {
    y: {
      type: "linear" as const,
      display: true,
      position: "left" as const,
    },
    y1: {
      type: "linear" as const,
      display: true,
      position: "right" as const,
    },
  },
  plugins: {
    title: {
      display: false,
    },
    legend: {
      align: "end" as const,
      labels: {
        usePointStyle: true,
        padding: 10,
        pointStyle: "circle",
        boxWidth: 5,
        boxHeight: 5,
      },
    },
    tooltip: {
      backgroundColor: "#FFF",
      titleFontSize: 16,
      titleColor: "#000000",
      bodyColor: "#000",
      bodySpacing: 6,
      bodyFontSize: 14,
      boxPadding: 5,
      usePointStyle: true,
      borderColor: "#D0D5DD",
      borderWidth: 1,
    },
  },
}

export const optionsOneYAxis = {
  responsive: true,
  maintainAspectRatio: true,
  aspectRatio: 4,
  interaction: {
    mode: "index" as const,
    intersect: false,
  },
  stacked: false,
  scales: {
    y: {
      type: "linear" as const,
      display: true,
      position: "left" as const,
    },
  },
  plugins: {
    title: {
      display: false,
    },
    legend: {
      align: "end" as const,
      labels: {
        usePointStyle: true,
        padding: 10,
        pointStyle: "circle",
        boxWidth: 5,
        boxHeight: 5,
      },
    },
    tooltip: {
      backgroundColor: "#FFF",
      titleFontSize: 16,
      titleColor: "#000000",
      bodyColor: "#000",
      bodySpacing: 6,
      bodyFontSize: 14,
      boxPadding: 5,
      usePointStyle: true,
      borderColor: "#D0D5DD",
      borderWidth: 1,
      callbacks: {
        label: function (context: TooltipItem<"line">) {
          const dataset = context.dataset as {
            label: string
            tooltip?: { isCurrency: boolean }
          }
          let label = context.dataset.label || ""
          if (label) {
            label += ": "
          }
          if (context.parsed.y !== null && dataset?.tooltip?.isCurrency) {
            label += new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(context.parsed.y)
          } else label += context.parsed.y
          return label
        },
      },
    },
  },
}

const MultipleLineChart = ({
  data,
  options,
}: {
  data: ChartData<"line", (number | [number, number] | null)[], unknown>
  options: any
}) => {
  const chartRef = useRef<ChartJS>(null)
  const openMenu = useAppSelector(selectCollapsedMenu)

  useEffect(() => {
    if (chartRef.current) {
      const chart = chartRef.current

      const resizeChart = () => {
        if (chart.canvas?.parentElement) {
          const parent = chart.canvas.parentElement
          chart.canvas.width = parent?.offsetWidth ?? 1000
          chart.canvas.height = parent?.offsetHeight ?? 250
          chart.resize()
        }
      }

      window.addEventListener("resize", resizeChart)

      return () => {
        window.removeEventListener("resize", resizeChart)
        chart.destroy()
      }
    }
  }, [])

  useEffect(() => {
    if (openMenu) {
      setTimeout(() => {
        if (chartRef.current) {
          const chart = chartRef.current

          if (chart.canvas?.parentElement) {
            const parent = chart.canvas.parentElement
            chart.canvas.width = parent?.offsetWidth ?? 1000
            chart.canvas.height = parent?.offsetHeight ?? 250
            chart.resize()
          }
        }
      }, 500)
    }
  }, [openMenu])

  return <Chart type="line" options={options} data={data} ref={chartRef} />
}

export default MultipleLineChart
