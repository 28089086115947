import { getHumanNumber } from "../../social/utils/getHumanNumbers"
import { MonthlyKPI } from "../OverviewScreen/overviewTypes"
import { GetOverviewResponse } from "../types/api-response"

export function OverviewResponseToOverviewKPIMapper(
  overviewResponse: GetOverviewResponse,
): {
  monthlyKPIs: MonthlyKPI[]
} {
  const monthlyKPIs: MonthlyKPI[] = overviewResponse.monthlyKPI.map(
    ({
      goalScore,
      kpi,
      month,
      monthChange,
      platform,
      isPercentage,
      monthGoal,
      goalDelta,
      isCurrency,
    }) => ({
      goalScore,
      kpi,
      month: getHumanNumber(month || 0),
      monthChange,
      platform,
      isPercentage,
      monthGoal: monthGoal ? getHumanNumber(monthGoal) : undefined,
      goalDelta,
      isCurrency,
    }),
  )

  return {
    monthlyKPIs,
  }
}
