import { useAppSelector } from "../../../../app/hooks"
import DownloadImageButton, {
  QUARTERLY_KPI_ID,
} from "../../../../app/shared/DownloadImageButton"
import {
  StackedBarChart,
  optionsStackedBar,
} from "../../../../app/shared/StackedBarChart"
import { selectMonth, selectQuarterlyKPIs } from "../overviewSlice"
import styles from "./index.module.scss"

const getMonthLabel = (initialMonth: number, addMonth: number): string => {
  const date = new Date()
  date.setMonth(initialMonth - 1 + addMonth)
  return new Intl.DateTimeFormat("en-US", { month: "long" }).format(date)
}

export const QuarterlyKPI = () => {
  const dataForChart = useAppSelector(selectQuarterlyKPIs)

  //TODO get data array from API and transform to differences,
  //1st value is currentMonthGoal
  //2nd: currentValue - currentMonthGoal //TODO what to do when it didn't reach the goal and the difference is negative?
  //3rd: nextMonthGoal
  //4th: nextNextMonthGoal

  const selectedMonth = useAppSelector(selectMonth)

  return (
    <div className={styles.quarterlyKPI} id={QUARTERLY_KPI_ID}>
      <div className={styles.header}>
        <h2 className={styles.title}>Quarterly KPI Progress Bars</h2>
        <DownloadImageButton id={QUARTERLY_KPI_ID} fileName={"quarterly-kpi"} />
      </div>

      <div className={styles.quarterlyKPIContainer}>
        {dataForChart.map((social) => (
          <div key={social.title} className={styles.socialContainer}>
            <div className={styles.title}>
              <img src={social.icon} alt="" className={styles.icon} />
              {social.title}
            </div>
            <div className={styles.chartContainer}>
              <StackedBarChart
                options={optionsStackedBar}
                data={{
                  labels: [""],
                  datasets: social.data.map((value, index) => ({
                    label: getMonthLabel(
                      selectedMonth.value as number,
                      index < 2 ? 0 : index - 1,
                    ),
                    data: [value],
                    backgroundColor: social.colors[index],
                  })),
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
