import { useMemo } from "react"
import { HISTORICAL_ID } from "../../../../app/shared/DownloadImageButton"
import styles from "./index.module.scss"
import { useAppSelector } from "../../../../app/hooks"
import {
  selectHomeMetrics,
  selectHomeStatus,
  selectMonth,
  selectYear,
} from "../../homeSlice"
import MultipleLineChart, {
  options,
  optionsOneYAxis,
} from "./MultipleLineChart"
import { ChartData } from "chart.js"
import Loading from "../../../../app/shared/Loading"
import { SliceStatus } from "../../../../app/sliceStatus"

export enum MeasureAxis {
  y = "y",
  y1 = "y1",
}

const HistoricalGraph = ({ selectedOption }: { selectedOption: string }) => {
  const selectedMonth = Number(useAppSelector(selectMonth).value)
  const selectedYear = useAppSelector(selectYear)
  const isLoading = useAppSelector(selectHomeStatus) === SliceStatus.loading
  const historicalMetrics = useAppSelector(selectHomeMetrics)?.historicalMetrics

  const startDate = useMemo(() => {
    const date = new Date()
    date.setMonth(selectedMonth - 1)
    date.setFullYear(Number(selectedYear.value))
    return date
  }, [selectedMonth, selectedYear.value])

  const data: ChartData<
    "line",
    (number | [number, number] | null)[],
    unknown
  >[] = useMemo(() => {
    startDate.setDate(1)
    startDate.setHours(0)
    startDate.setMinutes(0)
    startDate.setSeconds(0)
    const oneYearAgo = new Date(startDate)
    oneYearAgo.setFullYear(startDate.getFullYear() - 1)
    oneYearAgo.setMonth(startDate.getMonth())

    const labels = []

    if (!historicalMetrics) return []

    while (oneYearAgo <= startDate) {
      labels.push(
        `${new Intl.DateTimeFormat("en-US", { month: "short" }).format(
          oneYearAgo,
        )} ${oneYearAgo.getFullYear()}`,
      )

      oneYearAgo.setMonth(oneYearAgo.getMonth() + 1)
    }

    const colors = ["#92cdf4", "#f9c5c6", "#f16c60", "#EDBF85"]

    const datasets = historicalMetrics[selectedOption].map((metric, index) => ({
      label: metric.title,
      data: metric.values,
      borderColor: colors[index],
      backgroundColor: colors[index],
      yAxisID: MeasureAxis.y,
      cubicInterpolationMode: "monotone" as const,
      tooltip: {
        isCurrency: metric.isCurrency,
      },
    }))

    const result = [
      {
        labels,
        datasets: datasets,
      },
    ]

    return result
  }, [historicalMetrics, selectedOption, startDate])

  return (
    <div className={styles.historicalGraphContainer} id={HISTORICAL_ID}>
      {isLoading ? (
        <div className={`${styles.loading} ${styles.historicalGraph}`}>
          <Loading />
          <p>Loading...</p>
        </div>
      ) : (
        data.map((dataset, i) => (
          <div className={styles.historicalGraph} key={i}>
            <MultipleLineChart
              data={dataset}
              options={dataset.datasets.length > 1 ? options : optionsOneYAxis}
            />
          </div>
        ))
      )}
    </div>
  )
}

export default HistoricalGraph
