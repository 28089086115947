import { useEffect } from "react"
import { Link } from "react-router-dom"

import styles from "./index.module.scss"
import { MonthlyKPIView } from "./MonthlyKPIView"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import {
  getOverviewMetrics,
  selectAccount,
  selectHistoricalStatus,
  selectMonth,
  selectYear,
  setMonth,
  setSelectAccount,
  setYear,
} from "./overviewSlice"
import { SliceStatus } from "../../../app/sliceStatus"
import Loading from "../../../app/shared/Loading"
import Breadcrumb from "../../../app/shared/Breadcrumb"
import { FACEBOOK } from "../../../routes"
import { AppImages } from "../../../app/appImages"
import Header from "../Header"
import AccountSelector from "../../../app/shared/AccountSelector"
import { selectUser } from "../../user/userSlice"
import { UserType } from "../../user/userTypeEnum"

export function Facebook() {
  const dispatch = useAppDispatch()
  const isLoading =
    useAppSelector(selectHistoricalStatus) === SliceStatus.loading
  const selectedMonth = useAppSelector(selectMonth)
  const selectedYear = useAppSelector(selectYear)
  const currentUser = useAppSelector(selectUser)
  const userFacebookAccounts = currentUser?.facebookPages
  const selectedAccount = useAppSelector(selectAccount)

  useEffect(() => {
    dispatch(
      getOverviewMetrics({
        month: selectedMonth,
        year: selectedYear,
        account: selectedAccount,
      }),
    )
  }, [selectedYear, selectedMonth, selectedAccount])

  const facebookAccountOptions = [
    { label: "All", value: "" },
    ...(userFacebookAccounts?.map(({ id, name }) => ({
      label: name,
      value: id,
    })) || []),
  ]

  const selectedAccountOption = selectedAccount
    ? {
        value: selectedAccount,
        label:
          userFacebookAccounts?.find((x) => x.id === selectedAccount)?.name ??
          "",
      }
    : { label: "All", value: "" }

  const handleAccountChange = (newSelectedAccount: string) => {
    dispatch(setSelectAccount(newSelectedAccount))
  }

  return (
    <div className={styles.social}>
      <Header
        selectMonth={selectMonth}
        selectYear={selectYear}
        setMonth={setMonth}
        setYear={setYear}
      />

      <section className={styles.selectorContainer}>
        <Breadcrumb
          items={[
            <Link to={FACEBOOK} key={"home breadcrumb link"}>
              <img src={AppImages.graySocialIcon} alt="social icon" />
            </Link>,
            <Link to={`${FACEBOOK}`} key={"overview breadcrumb link"}>
              Overview
            </Link>,
          ]}
        />

        <div></div>

        {currentUser?.type !== UserType.FULLSAND && (
          <div className={styles.selector}>
            <AccountSelector
              options={facebookAccountOptions}
              account={selectedAccountOption}
              onAccountChange={handleAccountChange}
            />
          </div>
        )}
      </section>

      {isLoading ? (
        <div className={`${styles.loading} ${styles.historicalGraph}`}>
          <Loading />
        </div>
      ) : (
        <MonthlyKPIView />
      )}
    </div>
  )
}
