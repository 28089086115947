import {
  Chart as ChartJS,
  registerables,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  LineController,
} from "chart.js"
import { useEffect, useMemo, useRef } from "react"
import { Chart, Line } from "react-chartjs-2"
import { useAppSelector } from "../../../../app/hooks"
import { selectCollapsedMenu } from "../../../../app/layouts/Dashboard/menu/menuSlice"
import ChartDataLabels from "chartjs-plugin-datalabels"

ChartJS.register(
  ...registerables,
  LineController,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
)

const MultipleLineChart = ({
  data,
}: {
  data: ChartData<"line", (number | [number, number] | null)[], unknown>
}) => {
  const chartRef = useRef<ChartJS<"line">>(null)
  const openMenu = useAppSelector(selectCollapsedMenu)

  useEffect(() => {
    if (chartRef.current) {
      const chart = chartRef.current

      const resizeChart = () => {
        if (chart.canvas && chart.canvas.parentElement) {
          const parent = chart.canvas.parentElement
          chart.canvas.width = parent?.offsetWidth ?? 1000
          chart.canvas.height = parent?.offsetHeight ?? 250
          chart.resize()
        }
      }

      window.addEventListener("resize", resizeChart)

      return () => {
        window.removeEventListener("resize", resizeChart)
        chart.destroy()
      }
    }
  }, [])

  useEffect(() => {
    if (openMenu) {
      setTimeout(() => {
        if (chartRef.current) {
          const chart = chartRef.current

          if (chart.canvas && chart.canvas.parentElement) {
            const parent = chart.canvas.parentElement
            chart.canvas.width = parent?.offsetWidth ?? 1000
            chart.canvas.height = parent?.offsetHeight ?? 250
            chart.resize()
          }
        }
      }, 500)
    }
  }, [openMenu])

  const options = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: true,
      aspectRatio: 4,
      interaction: {
        mode: "index" as const,
        intersect: false,
      },
      stacked: false,
      scales: {
        y: {
          type: "linear" as const,
          display: true,
          position: "left" as const,
          ticks: {
            display: false,
          },
          grid: {
            display: true,
            drawOnChartArea: true,
            drawTicks: false,
          },
        },
        x: {
          display: true,
          ticks: {
            display: true,
          },
          grid: {
            display: false,
          },
        },
        max: Math.max(...(data as any).datasets[0].data) + 10,
      },
      plugins: {
        title: {
          display: false,
        },
        datalabels: {
          color: "black",
          font: {
            weight: "bold",
          },
          formatter: function (value: number) {
            return Number(value).toFixed(0)
          },
          align: "end",
          anchor: "end",
          offset: 0,
        },

        legend: {
          display: false,
          align: "end" as "end",
          labels: {
            usePointStyle: true,
            padding: 10,
            pointStyle: "circle",
            boxWidth: 5,
            boxHeight: 5,
          },
        },
        tooltip: {
          backgroundColor: "#FFF",
          titleFontSize: 16,
          titleColor: "#000000",
          bodyColor: "#000",
          bodySpacing: 6,
          bodyFontSize: 14,
          boxPadding: 5,
          usePointStyle: true,
          borderColor: "#D0D5DD",
          borderWidth: 1,
          callbacks: {
            label: function (context: any) {
              const yValue = context.parsed.y
              return `Value: ${yValue.toFixed(0)}`
            },
          },
        },
      },
    }),
    [data],
  )

  return (
    <Chart
      type="line"
      options={options as any}
      data={data}
      ref={chartRef as any}
      plugins={[ChartDataLabels]}
    />
  )
}

export default MultipleLineChart
