import { AppImages } from "../../appImages"
import styles from "./index.module.scss"

const MetricBadge = ({
  numericValue,
  isCurrency = false,
  isPercentage,
  stringValue,
}: {
  isPercentage: boolean
  isCurrency?: boolean
  numericValue?: number
  stringValue?: string
}) => {
  const isNegative = numericValue ? numericValue < 0 : false
  const isZero = numericValue === 0

  return (
    <p
      className={`${styles.badge} ${
        isNegative ? styles.negative : isZero ? styles.zero : styles.positive
      } `}
    >
      {!isZero && !stringValue && (
        <img
          src={
            isNegative ? AppImages.downRedArrowIcon : AppImages.upGreenArrowIcon
          }
          alt=""
          className={styles.arrowImage}
        />
      )}
      {stringValue ??
        `${isCurrency ? "$" : ""}${Math.abs(numericValue ?? 0).toFixed(2)}${
          isPercentage ? "%" : ""
        }`}
    </p>
  )
}

export default MetricBadge
