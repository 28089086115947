import { Link } from "react-router-dom"
import { useEffect } from "react"

import { AppImages } from "../../../app/appImages"
import Breadcrumb from "../../../app/shared/Breadcrumb"
import { PostSummary } from "./PostSummary"
import PostTable from "./PostTable"
import { SearchBar } from "./SearchBar"
import SortSelector from "./SortSelector"
import styles from "./index.module.scss"
import { CONTENT, TIKTOK } from "../../../routes"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import {
  getContentMetrics,
  selectMonth,
  selectYear,
  setMonth,
  setYear,
  setSelectedAccount,
  selectSearch,
  setSearch,
  setPage,
  setSortModel,
  selectAccount,
} from "./contentSlice"
import Header from "../Header"
import AccountSelector from "../../../app/shared/AccountSelector"
import { selectUser } from "../../user/userSlice"
import { UserType } from "../../user/userTypeEnum"

const TikTokContentScreen = () => {
  const dispatch = useAppDispatch()
  const selectedMonth = useAppSelector(selectMonth)
  const selectedYear = useAppSelector(selectYear)
  const selectedAccount = useAppSelector(selectAccount)
  const searchValue = useAppSelector(selectSearch)
  const currentUser = useAppSelector(selectUser)
  const userTikTokAccounts = currentUser?.tikTokAccounts

  useEffect(() => {
    dispatch(
      getContentMetrics({
        month: selectedMonth,
        year: selectedYear,
        account: selectedAccount,
      }),
    )
  }, [selectedYear, selectedMonth, selectedAccount])

  const tiktokAccountOptions = [
    { label: "All", value: "" },
    ...(userTikTokAccounts?.map(({ id, name }) => ({
      label: name,
      value: id,
    })) || []),
  ]

  const selectedAccountOption = selectedAccount
    ? {
        value: selectedAccount,
        label:
          userTikTokAccounts?.find((x) => x.id === selectedAccount)?.name ?? "",
      }
    : { label: "All", value: "" }

  const handleAccountChange = (newSelectedAccount: string) => {
    dispatch(setSelectedAccount(newSelectedAccount))
  }

  return (
    <div className={styles.social}>
      <Header
        selectMonth={selectMonth}
        selectYear={selectYear}
        setMonth={setMonth}
        setYear={setYear}
      />

      <section>
        <Breadcrumb
          items={[
            <Link to={TIKTOK}>
              <img src={AppImages.graySocialIcon} alt="social icon" />
            </Link>,
            <Link to={`${TIKTOK}${CONTENT}`}>Content</Link>,
          ]}
        />
      </section>

      {currentUser?.type !== UserType.FULLSAND && (
        <section className={styles.channel}>
          <div className={styles.header}>
            <div className={styles.titleContainer}>
              <p className={styles.title}>TikTok</p>
              <p className={styles.subtitle}>Select your handle</p>
            </div>
            <div className={styles.buttonsContainer}>
              <AccountSelector
                options={tiktokAccountOptions}
                account={selectedAccountOption}
                onAccountChange={handleAccountChange}
              />
            </div>
            <div></div>
          </div>
        </section>
      )}

      <section className={styles.summary}>
        <div className={styles.header}>
          <div className={styles.titleContainer}>
            <h2 className={styles.title}>Post Summary</h2>
          </div>
        </div>

        <PostSummary />
      </section>

      <section className={styles.details}>
        <div className={styles.header}>
          <h2 className={styles.title}>Post Details</h2>
          <div>
            <SearchBar
              searchValue={searchValue}
              onChange={(value) => {
                dispatch(setSearch(value))
                dispatch(setPage(1))
              }}
            />
            <SortSelector
              onChange={(field, direction) => {
                dispatch(setSortModel({ field, direction }))
                dispatch(setPage(1))
              }}
            />
          </div>
        </div>

        <PostTable />
      </section>
    </div>
  )
}

export default TikTokContentScreen
