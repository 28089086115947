export interface HomeMetrics {
  cardMetrics: HomeCardMetrics[]
  funnelMetrics: { [key: string]: HomeFunnelMetrics[] }
  historicalMetrics: {
    [key: string]: HomeHistoricalMetrics[]
  }
}

export enum MetricType {
  CURRENCY,
  NUMBER,
  PERCENTAGE,
}
export interface HomeCardMetrics {
  title: string
  description: string
  value: string
  type: MetricType
  difference?: number
}

export interface HomeFunnelMetrics {
  title?: string
  values: { value?: number; difference?: number }[]
  type?: MetricType
}

export interface HomeHistoricalMetrics {
  title: string
  subtitle: string
  values: number[]
  isCurrency?: boolean
}
